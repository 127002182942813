import { Client } from '@prismicio/client';
import RssParser from 'rss-parser';

import { initializeApollo } from 'lib/graphql/client';

import { VideosBySlugDocument, VideosBySlugQuery } from 'api';
import {
  PageSectionDocument,
  RssFeedSlice,
  TalksFeedSlice
} from 'prismic-types';

import cx from 'classnames';
import styles from 'components/PRISMIC/scss/pages.module.scss';
import { CSSProperties } from 'react';

export type ColumnProportion = '50/50' | string;

export function calculateLayoutValue(
  showNavigationColumn: boolean,
  hasTwoColumns: boolean,
  columnProportion: ColumnProportion
): number {
  if (hasTwoColumns) {
    if (columnProportion === '50/50') {
      return showNavigationColumn ? 2 / 3 : 1 / 2;
    }
    return showNavigationColumn ? 1 : 3 / 4;
  }
  return showNavigationColumn ? 3 / 4 : 1;
}

export function calculateSideColumnLayoutValue(
  showNavigationColumn: boolean,
  columnProportion: ColumnProportion
): number {
  if (columnProportion === '50/50') {
    return showNavigationColumn ? 2 / 3 : 1 / 2;
  }
  return showNavigationColumn ? 1 / 3 : 1 / 4;
}

export function getColumnClasses(
  hasTwoColumns: boolean,
  columnProportion: ColumnProportion
): string {
  return cx(
    hasTwoColumns &&
      (columnProportion === '50/50'
        ? styles['col-lg-6']
        : [styles.col, styles['col-lg-sidebarred']])
  );
}

export function getSideColumnClasses(
  columnProportion: ColumnProportion
): string {
  return cx(
    columnProportion === '50/50'
      ? styles['col-lg-6']
      : [styles.col, styles['col-lg-sidebar']]
  );
}

// If you need to use these classes as inline styles
export function getColumnStyle(
  hasTwoColumns: boolean,
  columnProportion: ColumnProportion
): CSSProperties {
  const classes = getColumnClasses(hasTwoColumns, columnProportion);
  return {
    className: classes
  } as CSSProperties;
}

export function getSideColumnStyle(
  columnProportion: ColumnProportion
): CSSProperties {
  const classes = getSideColumnClasses(columnProportion);
  return {
    className: classes
  } as CSSProperties;
}

export async function getAllSections(
  client: Client
): Promise<PageSectionDocument[]> {
  return await client.getAllByType('page_section', {
    graphQuery: `
      {
        page_section {
          ...page_sectionFields
          default_page {
            ...on page {
              uid
            }
          }
        }
      }`
  });
}

export const rssParser = new RssParser({
  customFields: { item: ['media:thumbnail'] }
});

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchRSSFeedWithRetry(url: string, retries = 0) {
  try {
    return await rssParser.parseURL(url);
  } catch (e) {
    if ((e as { statusCode?: number })?.statusCode === 410) {
      console.error(`RSS feed is gone (410): ${url}`);
      return { error: 'RSS feed is no longer available.' };
    } else if (retries < MAX_RETRIES) {
      await new Promise(resolve =>
        setTimeout(resolve, RETRY_DELAY * Math.pow(2, retries))
      );
      return fetchRSSFeedWithRetry(url, retries + 1);
    } else {
      console.error(`Failed to fetch RSS feed: ${url}`, e);
      return { error: 'Failed to fetch RSS feed.' };
    }
  }
}

export const staticPropSlicesMap: Record<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (slice: any) => Promise<unknown>
> = {
  talks_feed: async (slice: TalksFeedSlice) => {
    const apolloClient = initializeApollo();
    return (
      await apolloClient.query<VideosBySlugQuery>({
        query: VideosBySlugDocument,
        variables: { slug: slice.items.map(it => it.slug) }
      })
    ).data.videos?.nodes;
  },
  rss_feed: async (slice: RssFeedSlice) => {
    if (!slice.primary.feed_url) return null;

    const result = await fetchRSSFeedWithRetry(slice.primary.feed_url);

    if ('error' in result) {
      return {
        error: result.error,
        fallbackContent: 'Sorry, this content is currently unavailable.'
      };
    }

    const slicedItems = result.items.slice(0, slice.primary.items_to_show || 0);
    return { items: slicedItems };
  }
};
