import { SliceZone } from '@prismicio/react';
import cx from 'classnames';
import styles from 'components/PRISMIC/scss/pages.module.scss';
import { components } from 'components/PRISMIC/slices';
import { PageDocument } from 'prismic-types';
import { createContext } from 'react';
import { PageProps } from '.';
import {
  ColumnProportion,
  calculateLayoutValue,
  calculateSideColumnLayoutValue,
  getColumnClasses,
  getSideColumnClasses
} from '../utils';

interface MainColumnProps {
  page: PageDocument;
  showNavigationColumn: boolean;
  hasTwoColumns: boolean;
  columnProportion: ColumnProportion;
}

interface SideColumnProps {
  page: PageDocument;
  showNavigationColumn: boolean;
  columnProportion: ColumnProportion;
}

export const PageLayoutContext = createContext(1);

export default function PageContent({ page }: Pick<PageProps, 'page'>) {
  const showNavigationColumn = page.data.show_navigation_column;
  const hasTwoColumns = page.data.second_column_enabled;
  const columnProportion = page.data.column_proportion;

  return (
    <div className={cx(hasTwoColumns && styles.row)}>
      <MainColumn
        page={page}
        showNavigationColumn={showNavigationColumn}
        hasTwoColumns={hasTwoColumns}
        columnProportion={columnProportion}
      />
      {hasTwoColumns && (
        <SideColumn
          page={page}
          showNavigationColumn={showNavigationColumn}
          columnProportion={columnProportion}
        />
      )}
    </div>
  );
}

function MainColumn({
  page,
  showNavigationColumn,
  hasTwoColumns,
  columnProportion
}: MainColumnProps) {
  const layoutValue = calculateLayoutValue(
    showNavigationColumn,
    hasTwoColumns,
    columnProportion
  );

  return (
    <div className={getColumnClasses(hasTwoColumns, columnProportion)}>
      <PageLayoutContext value={layoutValue}>
        <SliceZone slices={page.data.slices} components={components} />
      </PageLayoutContext>
    </div>
  );
}

function SideColumn({
  page,
  showNavigationColumn,
  columnProportion
}: SideColumnProps) {
  const layoutValue = calculateSideColumnLayoutValue(
    showNavigationColumn,
    columnProportion
  );

  return (
    <div className={getSideColumnClasses(columnProportion)}>
      <PageLayoutContext value={layoutValue}>
        <SliceZone slices={page.data.slices1} components={components} />
      </PageLayoutContext>
    </div>
  );
}
